@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"),
    url(../fonts/Merriweather-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"),
    url(../fonts/Merriweather-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"),
    url(../fonts/Merriweather-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(../fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(../fonts/OpenSans-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(../fonts/OpenSans-SemiBold.ttf) format("truetype");
  font-weight: semi-bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../fonts/Roboto-Light.ttf) format("truetype");
  font-weight: light;
  font-style: normal;
}

img {
  max-width: 100%;
}

iframe {
  z-index: 1100 !important;
}

/* 3Box Comments */
.comments_footer {
  overflow-x: hidden !important;
}
